<template>
  <div class="box">
    <div class="img"><img :src="url" alt=""></div>
    <div class="text">请跳转到课堂互动系统，或复制链接后重新登录</div>
    <div class="url">
      <span class="mr-2">课堂互动系统地址</span>
      <span>{{ text }}</span>
      <span class="text-primary ml-3 c-p" @click="handleCopy">
        <i class="el-icon-copy-document"></i>
        <span style="margin-left: 4rem;">复制链接</span>
      </span>
    </div>
    <div>
      <el-button class="btn" type="primary" @click="handlePath">点击跳转</el-button>
      <el-button class="btn" type="primary" plain @click="handleDownFile">下载操作手册</el-button>
    </div>
  </div>
</template>

<script>
import { fileDownload } from '@/api'
import { copyText } from '@/utils'
export default {
  _config: {route: {path: "list", meta: {title: "详情", keepAlive: true}}},
  data() {
    return {
      url: process.env.VUE_APP_URL_OSS + "pic/i-wait.png",
      text: "https://cms.pre.honghueducation.com/logistics/campus-operations/questionnaire/list"
    }
  },
  created() {
    this.text = process.env.VUE_APP_URL_BLACK
  },
  methods: {
    handleCopy() {
      copyText(this.text)
    },
    handlePath() {
      window.open(this.text)
    },
    handleDownFile() {
      const params = { name: '答题反馈操作手册', type: 'pdf', show: 1 }
      const api = `${process.env.VUE_APP_URL_OSS}operation/operation2.pdf`
      fileDownload(api, {}, params)
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .img {
    width: 350px;
    height: 224px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover
    }
  }
  .text {
    margin: 40rem 0;
    font-size: 20rem;
    font-weight: 600;
    text-align: center;
    p {
      margin: 10rem 0;
    }
  }
  .url {
    margin-bottom: 30rem;
    padding: 10rem 20rem;
    font-size: 16rem;
    color: #666;
    background-color: #f5f5f5;
    box-shadow: 1rem 1rem 1rem 0 rgba(61,60,60,0.22);
    border-radius: 3rem;
  }
  .btn {
    width: 120rem;
    + .btn {
      margin-left: 30rem;
    }
  }
}
</style>
